import { ClassData } from '@models/class-data';
import { DataField } from './data-field.model';

export class DataFieldData {
  id: number;

  dataFieldId: number;
  dataField?: DataField;

  ticketEventAttendeeId?: number;

  // one answer based on data field type
  dataFieldOptionId?: number;
  openAnswer?: string;
  boolAnswer?: boolean;

  constructor(json: ClassData<DataFieldData>) {
    this.id = +json.id;

    this.dataFieldId = +json.dataFieldId;
    if (json.dataField) {
      this.dataField = new DataField(json.dataField);
    }

    if (json.ticketEventAttendeeId) {
      this.ticketEventAttendeeId = +json.ticketEventAttendeeId;
    }

    if (json.dataFieldOptionId) {
      this.dataFieldOptionId = +json.dataFieldOptionId;
    }
    this.openAnswer = json.openAnswer;
    if (json.boolAnswer !== undefined) {
      this.boolAnswer = json.boolAnswer;
    }
  }
}
